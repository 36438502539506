import React from "react";

const AccordionItem = ({
  genreType,
  answer,
  isOpen,
  onClick,
  setRequestedTrack,
  pickTrack,
}) => {
  // console.log("isOpen", isOpen);
  const contentHeight = React.useRef();
  return (
    <div className="wrapper">
      <button
        className={`genreType-container ${isOpen ? "active" : ""}`}
        onClick={onClick}
      >
        <h3 className="genreType-content text-white text-lg font-medium uppercase">{genreType}</h3>
        <svg
          className={`text-white arrow ${isOpen ? "active" : ""}`}
          width="20px"
          height="20px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http:www.w3.org/2000/svg"
        >
          <path
            d="M6 9L12 15L18 9"
            stroke="#ffffff"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>

      <div
        ref={contentHeight}
        className={`answer-container ${isOpen ? "active" : ""}`}
      >
        <ul className="divide-y divide-gray-200">
          {answer.map((item, i) => (
            <li
              className="flex items-center py-4 px-3 jukebox-divider"
              key={i}
              onClick={(event) => {
                setRequestedTrack(item);
                pickTrack(event, item, i);
              }}
            >
              <span className="text-gray-100 text-lg font-black font-medium">
                {i + 1}.
              </span>
              <img
                className=" rounded-full object-cover mr-1"
                src={item.coverart}
                style={{ width: "48px" }}
                alt={item.track}
              />
              <div className="flex-1" style={{ width: "50%" }}>
                <h3 className="text-lg font-medium text-white overflow-hidden">
                  {item.artist}
                </h3>
                <p className="text-gray-100 text-base">{item.track} </p>
                <p className="text-gray-100 text-base">{item.genre} </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const TrackList = ({ tracks, setRequestedTrack, pickTrack }) => {
  const [activeIndex, setActiveIndex] = React.useState(null);

  const handleItemClick = (index) => {
    // console.log("activeIndex", activeIndex);
    // console.log("genre clicked", index);
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  // Group tracks by genre
  const groupedTracks = groupTracksByGenre(tracks);

  return (
    <div className="accordion-list">
      {Object.keys(groupedTracks).map((genre) => (
        <div
          key={genre}
          className="accordion-container"
        >
          <AccordionItem
            key={genre}
            genreType={genre}
            answer={groupedTracks[genre]}
            isOpen={activeIndex === genre}
            onClick={() => handleItemClick(genre)}
            setRequestedTrack={setRequestedTrack}
            pickTrack={pickTrack}
          />
        </div>
      ))}
    </div>
  );
};

// Utility function to group tracks by genre
const groupTracksByGenre = (tracks) => {
  return tracks.reduce((acc, track) => {
    if (!acc[track.genrekey]) {
      acc[track.genrekey] = [];
    }
    acc[track.genrekey].push(track);
    return acc;
  }, {});
};

const App = ({ data, pickTrack, setRequestedTrack }) => (
  <div>
    <h2 className="text-white">Music Tracks by Genre</h2>
    <TrackList
      tracks={data}
      pickTrack={pickTrack}
      setRequestedTrack={setRequestedTrack}
    />
  </div>
);

export default App;
