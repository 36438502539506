import React, { useState } from "react";
import { Auth } from "./components/Auth.js";
import { AppLayout } from "./components/AppLayout";
import Cookies from "universal-cookie";
import "./AppMuuzbox.scss";
import { AuthProvider } from "./context/AuthUserContext";

const cookies = new Cookies();

function ChatApp() {
  const [isAuth, setIsAuth] = useState(cookies.get("auth-token"));
  const [isInChat, setIsInChat] = useState(null);
  const [chatId, setChatId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  if (!isAuth) {
    return (
      <AuthProvider>
        <AppLayout
          isAuth={isAuth}
          setIsAuth={setIsAuth}
          setIsInChat={setIsInChat}
        >
          <div
            className="bg-black bg-opacity-80 app-container max-w-full"
            x="bg-black bg-opacity-80 h-screen w-screen top-0 left-0 fixed z-10"
          >
            <div className="h-screen flex items-center justify-center">
              <Auth setIsAuth={setIsAuth} />
            </div>
          </div>
        </AppLayout>
      </AuthProvider>
    );
  }

  return (
    <AuthProvider>
      <AppLayout
        isAuth={isAuth}
        setIsAuth={setIsAuth}
        setIsInChat={setIsInChat}
        setChatId={setChatId}
      ></AppLayout>
    </AuthProvider>
  );
}

export default ChatApp;
