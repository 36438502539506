import React, { useState, useEffect } from "react";
import { db, auth } from "../firebase-config";
import {
  collection,
  onSnapshot,
  query,
  doc,
  getDoc,
  setDoc,
  serverTimestamp,
  updateDoc,
  deleteDoc,
  where,
  orderBy,

  // orderBy, // eventualy order by name
} from "firebase/firestore";
import { Avatar } from "./Avatar";
import VerticalCarousel from "./VerticalCarousel";
import { useAuth } from "../context/AuthUserContext";

import "../styles/Chat.scss";

export const HiddenChatList = ({ pickedChat, thinLayout }) => {
  const { currentUser, loading } = useAuth();
  const [chatlist, setChatlist] = useState([]);
  const [prevChatlist, setPrevChatlist] = useState([]);
  const [avatars, setAvatars] = useState({});
  const [requestList, setRequestList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [currentUserView, setCurrentUserView] = useState({});

  useEffect(() => {
    setTimeout(() => {
      let gotCurrentChatId = "";
      new Promise((resolve, reject) => {
        gotCurrentChatId = getCurrentChat();
        if (gotCurrentChatId !== "") {
          resolve(gotCurrentChatId);
        } else reject();
      }).then((results) => {
        getChatList(results);
      });
    }, 2000);
  }, []);

  const openChat = async (e, chatId, type) => {
    e.preventDefault();
    let trackDetailsIndex = requestList.findIndex(
      (item) => item.chatId === chatId
    );
    pickedChat(chatId, type, requestList[trackDetailsIndex]);

    const docRef = doc(db, "users", auth.currentUser.uid);
    const docSnap = await getDoc(docRef);
    const currentChat = { currentChat: chatId };
    if (docSnap.exists()) {
      try {
        await updateDoc(docRef, { ...currentChat });
      } catch (err) {
        console.log(err);
      }
    }
    setTimeout(() => {
      getChatList(chatId, requestList);
    }, 2000);
  };

  const openUser = async (e) => {
    e.preventDefault();
    console.log(
      "open modal to user and details, plus a button to open this chat"
    );
  };

  const getCurrentChat = async () => {
    const docRef = doc(db, "users", auth.currentUser.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data().currentChat;
    }
  };

  // const getDJChatList = async (results) => {
  //   const requestFeedbackRef = collection(db, "djchats");
  //   const queryMessages = query(
  //     requestFeedbackRef,
  //     where("uid", "==", auth.currentUser.uid),
  //     orderBy("createdAt")
  //   );

  //   const unsuscribe = onSnapshot(queryMessages, (snapshot) => {
  //     let requestFeedback = [];
  //     snapshot.forEach((doc) => {
  //       if (!(doc.data().status && doc.data().status === "closed")) {
  //         requestFeedback.push({
  //           // ...doc.data(),
  //           chatId: doc.id,
  //           chatLength: 0,
  //           createdAt: doc.data().createdAt,
  //           id: doc.id,
  //           type: "djchat",
  //           unreadAmount: doc.data().unreadAmount,
  //           users: [{ id: "put-the-dj-user-id-here" }],
  //           coverart: doc.data().request.coverart,
  //           track: doc.data().request.track,
  //         });
  //       }
  //     });

  //     console.log("requestFeedback", requestFeedback);
  //     // send requestFeedback to ChatList component and add it to the list

  //     setRequestList(requestFeedback);
  //     getChatList(results, requestFeedback);
  //   });
  //   return () => unsuscribe();
  // };

  const getChatList = async (currentChatId, requestFeedback) => {
    if (auth.currentUser) {
      const chatRef = collection(db, "users", auth.currentUser.uid, "chats");
      const queryChats = query(
        chatRef /*,
      where("room", "==", room),
      orderBy("createdAt")*/
      );
      const unsuscribe = onSnapshot(queryChats, (snapshot) => {
        let chats = [];
        let avatars = [];
        snapshot.forEach((doc) => {
          const chatUsers = doc.data().users;
          //ignore hidden chats
          if (doc.data().status && doc.data().status === "hidden" && doc.data().deleted !== "true") {
            avatars.push(chatUsers[1].id);
            chats.push({ ...doc.data(), id: doc.id, uid: chatUsers[1].id });
            // get the length of each chat
          }
        });
        new Promise((resolve, reject) => {
          const avatarObj = {};
          let i = 0;
          // search for these uids and the photoURL that goes with them
          avatars.forEach(async (uid) => {
            i++;
            const docRef = doc(db, "users", uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              avatarObj[uid] = {
                displayName: docSnap.data().displayName,
                photoURL: docSnap.data().photoURL,
                email: docSnap.data().email,
              };
            } else {
              // some random image maybe an ai generated icon and displayname
              console.log("no docSnap.exists()");
            }
          });
          if (i >= avatars.length) resolve(avatarObj);
          else reject();
        })
          .then(function (avatarObj) {
            return new Promise((resolve, reject) => {
              console.log('Hidden Chat List avatarObj', avatarObj)
              setTimeout(() => resolve(setAvatars(avatarObj)), 500);
            });
          })
          .then((res) => {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                const newChatlist = [];
                chats.forEach(async (item, i) => {
                  const lclRef = doc(
                    db,
                    "users",
                    auth.currentUser.uid,
                    "updatewatchList",
                    item.chatId
                  );
                  const lclDoc = await getDoc(lclRef);
                  if (lclDoc.exists()) {
                    let unreadAmount;
                    if (item.chatLength === undefined) {
                      unreadAmount = 0;
                    } else {
                      let x = item.chatLength - lclDoc.data().chatLength;
                      if (x < 0) unreadAmount = 0;
                      else
                        unreadAmount =
                          item.chatLength - lclDoc.data().chatLength;
                    }

                    // if its the current chat open
                    if (lclDoc.data().chatId === currentChatId) {
                      item.unreadAmount = 0;
                    } else {
                      item.unreadAmount = unreadAmount;
                      if (item.unreadAmount > 0) {
                        console.log("item.unreadAmount", item.unreadAmount);
                        console.log("index i", i);
                      }
                    }

                    newChatlist.push(item);
                  } else {
                    // lclDoc doesn't exists yet so let insert on
                    const updatewatchListRef2 = doc(
                      db,
                      "users",
                      auth.currentUser.uid,
                      "updatewatchList",
                      item.chatId
                    );

                    try {
                      const thisChat2 = {
                        chatId: item.chatId,
                        updatedAt: serverTimestamp(),
                        chatLength: 0,
                      };
                      const updatewatchListDocSnap = await getDoc(
                        updatewatchListRef2
                      );
                      if (updatewatchListDocSnap.exists()) {
                        await setDoc(
                          doc(
                            db,
                            "users",
                            auth.currentUser.uid,
                            "updatewatchList",
                            item.chatId
                          ),
                          thisChat2
                        );
                      } else {
                        await setDoc(
                          doc(
                            db,
                            "users",
                            auth.currentUser.uid,
                            "updatewatchList",
                            item.chatId
                          ),
                          thisChat2
                        );
                      }
                    } catch (err) {
                      console.log(err);
                    }
                  }
                });
                if (newChatlist.length >= 0) resolve(newChatlist);
                else reject();
              }, 500);
            });
          })
          .then((newChatlist) => {
            if (prevChatlist.length > 0) {
              // has previous chat
              // compare unreadAmounts adn collect indexes
              // console.log('changes', objectsAreSame(newChatlist, prevChatlist))
              setPrevChatlist(newChatlist);
            } else {
              setPrevChatlist(newChatlist);
            }

            return new Promise((resolve, reject) => {
              setTimeout(() => {
                let combinedList;
                if (requestFeedback)
                  combinedList = newChatlist.concat(requestFeedback);
                else combinedList = newChatlist;
                console.log("combinedList", combinedList);
                resolve(setChatlist(combinedList));
              }, 500);
            });
          });
      });
      return () => unsuscribe();
    }
  };

  const ChatUser = ({ chatId }) => {
    const index = chatlist.findIndex((message) => {
      return !(
        auth.currentUser.displayName === message.user ||
        auth.currentUser.email === message.user
      )
        ? message.uid
        : null;
    });
    console.log("found index", index);
    console.log("users for these chatlist", chatlist[index]);
    console.log("avatars", avatars);
    const uid = chatlist[index]?.uid || null;
    return (
      <div className="flex flex-row items-center">
        {uid && (
          <div className="flex items-center justify-center h-14 w-14 rounded-full border overflow-hidden bg-indigo-500 flex-shrink-0 z-10 mr-3">
            <Avatar avObj={avatars} uid={uid} />
          </div>
        )}
        <div>{avatars[uid]?.displayName}</div>
        <button className="hide-chat">
          <svg
            fill="#ffffff"
            width="20px"
            height="20px"
            viewBox="0 0 48 48"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 0h48v48H0z" fill="none" />
            <g id="Shopicon">
              <path
                d="M11.957,33.215L7.172,38L10,40.828l5.305-5.305C17.867,36.992,20.788,38,24,38c12,0,20-14,20-14s-2.954-5.16-7.957-9.215
		L40.828,10L38,7.172l-5.305,5.305C30.133,11.008,27.212,10,24,10C12,10,4,24,4,24S6.954,29.16,11.957,33.215z M33.204,17.624
		c2.668,2.091,4.747,4.638,5.996,6.369C36.728,27.396,31.024,34,24,34c-2.048,0-3.973-0.563-5.742-1.43l1.684-1.684
		C21.133,31.589,22.517,32,24,32c4.418,0,8-3.582,8-8c0-1.483-0.411-2.867-1.114-4.058L33.204,17.624z M20.149,25.023
		C20.062,24.694,20,24.356,20,24c0-2.206,1.794-4,4-4c0.356,0,0.694,0.062,1.023,0.149L20.149,25.023z M27.851,22.977
		C27.938,23.306,28,23.644,28,24c0,2.206-1.794,4-4,4c-0.356,0-0.694-0.062-1.023-0.149L27.851,22.977z M24,14
		c2.048,0,3.973,0.563,5.742,1.43l-1.684,1.684C26.867,16.411,25.483,16,24,16c-4.418,0-8,3.582-8,8
		c0,1.483,0.411,2.867,1.114,4.058l-2.318,2.318c-2.668-2.091-4.747-4.638-5.997-6.369C11.272,20.604,16.976,14,24,14z"
              />
            </g>
          </svg>
        </button>
      </div>
    );
  };

  const CurrentUserView = ({ userchat }) => {
    return (
      <>
        <div>
          <Avatar avObj={avatars} uid={userchat.uid} />
        </div>
        <div> {userchat.users[1].displayName}</div>
      </>
    );
  };

  const deleteChat = async () => {
    const deleteRef = doc(db, "users", auth.currentUser.uid, "chats", currentUserView.id);
    const currentChat = {
      deleted: "true"
    }
    try {
      setIsDeleteOpen(!isDeleteOpen)
      await updateDoc(deleteRef, currentChat);
    } catch (err) {
      console.log(err);
    }
  };

  // checks if there are new unread messages for each chat in the list here
  // onsnap reacts to the chat having the update field modified then reads
  // the messages and compares length to previous length

  return (
    <>
      {currentUser && thinLayout ? (
        <>
          <VerticalCarousel
            avatars={avatars}
            data={chatlist}
            openChat={openChat}
          />
        </>
      ) : (
        <>
          {isOpen && (
            <div className="bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 muuz-confirm-panel">
              <div
                className="py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownDefaultButton"
              >
                <div className="text-center">
                  <div
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                    class="muuzbox-section-button"
                  >
                    <div
                      id="notifyMuuzbox-close"
                      class="section-close muuzbox-close rounded"
                    ></div>
                  </div>

                  <button
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                    className="block w-full text-center px-4 py-2 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white muuzmenuitem"
                  >
                    <div>
                      <CurrentUserView userchat={currentUserView} />
                      <strong>XXX</strong>
                    </div>
                    <div>XXXX</div>
                  </button>
                  <div className="block w-full text-xl text-center text-red-800">
                    DJ OBAH has been informed of your request
                  </div>
                </div>
              </div>
            </div>
          )}
          {isDeleteOpen && (
            <div className="bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 muuz-confirm-delete-panel">
              <div
                className="py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownDefaultButton"
              >
                <div className="text-center">
                  <div
                    onClick={() => {
                      setIsDeleteOpen(!isDeleteOpen);
                    }}
                    class="muuzbox-section-button"
                  >
                    <div
                      id="notifyMuuzbox-close"
                      class="section-close muuzbox-close rounded"
                    ></div>
                  </div>

                  <button
                    onClick={() => {
                      setIsDeleteOpen(!isDeleteOpen);
                    }}
                    className="block w-full text-center px-4 py-2 hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white muuzmenuitem"
                  >
                    <div>
                      <CurrentUserView userchat={currentUserView} />
                    </div>
                  </button>
                  <div className="block w-full text-xl text-center text-red-800 m-4">
                    Are you sure you want to delete this chat
                  </div>
                  <button
                    onClick={() => {
                      deleteChat();
                    }}
                    className="text-lg bg-red-500 p-4 rounded-2xl text-white"
                  >
                    DELETE CHAT
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-col flex-auto p-6 jukebox-panel hiddenchat">
            <div className="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 dark:bg-gray-900 h-full p-4 panel-fade">
              <div
                className={`flex flex-row items-center h-16 rounded-xl w-full px-4 mb-4 text-enter-fit`}
              >
                <div>
                  <button className="flex items-center justify-center text-gray-400 hover:text-gray-600">
                    <svg
                      className="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
              <div className="flex flex-col h-full overflow-x-auto mb-4">
                <div className="flex flex-col h-full">
                  <div className="grid grid-cols-12 gap-y-2">
                    {chatlist.map((chat, i) => (
                      <div
                        className="col-start-1 col-end-13 p-3 rounded-lg"
                        key={chat.id}
                      >
                        <div className="flex flex-row items-center">
                          <button
                            className="flex flex-row items-center text-gray-800 dark:text-gray-400  hover:bg-white bg-gray-200 dark:bg-gray-800 rounded-xl p-2 w-full"
                            // onClick={(e) => openChat(e, chat.chatId, chat.type)}
                            // onClick={() => {
                            //   setCurrentUserView(chat);
                            //   setIsOpen(!isOpen);
                            // }}
                          >
                            <div
                              onClick={() => {
                                setCurrentUserView(chat);
                                setIsOpen(!isOpen);
                              }}
                              className="flex items-center justify-center h-16 w-16 rounded-full border overflow-hidden bg-indigo-500 flex-shrink-0"
                            >
                              <Avatar avObj={avatars} uid={chat.uid} />
                            </div>
                            <div className="ml-2 text-lg font-semibold">
                              <div
                                style={{ width: "100px", overflowX: "hidden" }}
                                className="text-left text-ellipsis"
                                onClick={(e) =>
                                  openChat(e, chat.chatId, chat.type)
                                }
                              >
                                {chat.users[1].displayName}
                                {/* <ChatUser chatId={chatId}/> */}
                                {/* {user.displayName === "" ? (
                                  <>{user.email}</>
                                ) : (
                                  <>{user.displayName}</>
                                )} */}
                              </div>
                            </div>
                            <div
                              onClick={(e) =>
                                openChat(e, chat.chatId, chat.type)
                              }
                              className="flex items-center justify-center ml-auto text-xs text-white bg-red-500 h-4 w-4 rounded leading-none"
                            >
                              {chat.unreadAmount}
                            </div>
                            <div
                              onClick={() => {
                                setCurrentUserView(chat);
                                setIsDeleteOpen(!isDeleteOpen);
                              }}
                              className="flex items-center justify-center ml-auto text-sm  h-4 w-4 rounded leading-none"
                            >
                              <strong>X</strong>
                            </div>
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
